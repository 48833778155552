import React, { useState } from 'react';

import { EmptyItem, SimpleItem, Label, RequiredRule } from 'devextreme-react/form';

import { EntityEditPage, useSvcDatasource, useSvcSelectBoxConfig } from '@/svc';
import { DatagridSelectBox, FlagsListComponent, TreeSelectBox } from '@/ui/components';
import { CorsiScadenzePage } from '..';
import { Column } from 'devextreme-react/data-grid';

export default function ClientiEditPage() {
  const [displayComuniRecordField, setDisplayComuniRecordField] = useState(true);
  const [displayScadenzeBox, setDisplayScadenzeBox] = useState(false);
  const [currentDatarow, setCurrentDatarow] = useState(null);
  let filtriSelectComuni = void 0;

  const displayCodiciAteco = process.env.REACT_APP_PAGE_CLIENTI_USE_CODICI_ATECO === '1';
  const displayNumDipendenti = process.env.REACT_APP_PAGE_CLIENTI_USE_NUM_DIPENDENTI === '1';

  // check filtri standard
  const filtriDefault = process.env.REACT_APP_PAGE_CLIENTI_COMUNI_DEFAULT_FILTER;
  if (filtriDefault) {
    filtriSelectComuni = JSON.parse(filtriDefault);
  }

  const onDatarowReady = (datarow) => {
    if (datarow.id > 0) setDisplayScadenzeBox(true);
    setCurrentDatarow(datarow);
  }
  
  const comuniSelectBoxConfig = useSvcSelectBoxConfig({
    domain: 'Core',
    entity: 'comuni',
    paginate: true,
    filters: filtriSelectComuni
  });

  const clientiCategorieDataSource = useSvcDatasource({
    domain: 'Core',
    entity: 'clientiCategorie'
  });

  const codiciAtecoDatasource = useSvcDatasource({
    domain: 'Core',
    entity: 'codiciAteco',
    lookupKey: 'codice'
  });

  return <EntityEditPage
    domain='Core'
    entity='clienti'
    onDatarowReady={onDatarowReady}>
      <EntityEditPage.EditForm>
        {(datarow, setDatarowField) => {
          if (datarow.comune && displayComuniRecordField) {
            setDisplayComuniRecordField(false);
          }
          return (
            <React.Fragment>
              <SimpleItem colSpan={2} dataField="nome" editorType="dxTextBox">
                <Label text="Ragione Sociale" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem colSpan={2} dataField="indirizzo" editorType="dxTextBox">
                <Label text="Indirizzo" />
                <RequiredRule />
              </SimpleItem>
              <SimpleItem
                dataField="comuniRecord"
                editorType="dxLookup"
                visible={displayComuniRecordField}
                editorOptions={comuniSelectBoxConfig}>
                  <Label text="Località" />
              </SimpleItem>
              <SimpleItem
                dataField="comune"
                editorType="dxTextBox"
                visible={!displayComuniRecordField}
                >
                  <Label text="Località" />
              </SimpleItem>
              <SimpleItem dataField="piva" editorType="dxTextBox">
                <Label text="P. Iva" />
              </SimpleItem>
              <SimpleItem dataField="cfisc" editorType="dxTextBox">
                <Label text="Codice Fiscale" />
              </SimpleItem>
              <SimpleItem dataField="telefono" editorType="dxTextBox">
                <Label text="Telefono" />
              </SimpleItem>
              <SimpleItem colSpan={2} dataField="email" editorType="dxTextBox">
                <Label text="E-mail" />
              </SimpleItem>
              <SimpleItem colSpan={2} dataField="pec" editorType="dxTextBox">
                <Label text="PEC" />
              </SimpleItem>
              <SimpleItem dataField="fax" editorType="dxTextBox">
                <Label text="Fax" />
              </SimpleItem>
              <SimpleItem dataField="web" editorType="dxTextBox">
                <Label text="Sito web" />
              </SimpleItem>
              <SimpleItem 
                dataField="codice_ufficio" 
                editorType="dxTextBox"
                editorOptions={{
                  maxLength: 7
                }}
                >
                <Label text="Codice Ufficio" />
              </SimpleItem>
              <SimpleItem 
                visible={displayNumDipendenti}
                ataField="num_dipendenti" 
                editorType="dxNumberBox">
                <Label text="Numero di dipendenti" />
              </SimpleItem>
              <SimpleItem colSpan={4}>
                <Label text="Categorie" />
                <TreeSelectBox
                  multiple
                  dataSource={clientiCategorieDataSource}
                  value={datarow.clientiCategorieList}
                  onValueChanged={setDatarowField('clientiCategorieList')} />
              </SimpleItem>
              <SimpleItem
                visible={displayCodiciAteco}
                colSpan={2}
              >
                  <Label text="Codici Ateco" />
                  <DatagridSelectBox
                    multiple
                    dataSource={codiciAtecoDatasource}
                    valueExpr='codice'
                    displayExpr='codice'
                    value={datarow.codiciAteco}
                    onValueChanged={setDatarowField('codiciAteco')}
                    ColumnsComponents={[
                      <Column
                        dataField='codice'
                        caption='Codice'
                        dataType='string'
                      />,
                      <Column
                        dataField='displayText'
                        caption='Descrizione'
                        dataType='string'
                      />
                    ]}
                  />
              </SimpleItem>
              <SimpleItem colSpan={4}>
                <Label text="Lista Proprietà" />
                <FlagsListComponent
                  items={datarow.flagsList}
                  setItems={setDatarowField('flagsList')}
                />
              </SimpleItem>
              {/*
              <SimpleItem visible={clientiCategorieUseCodiceGestionale} dataField="codice_gestionale" editorType="dxNumberBox">
              </SimpleItem>
              <SimpleItem dataField="descrizione" editorType="dxTextBox">
                <RequiredRule />
              </SimpleItem>
              <SimpleItem visible={clientiCategorieUseFlagImportante} dataField="flag_importante" editorType="dxCheckBox">
                <Label text="Importante" />
              </SimpleItem>
              <SimpleItem
                visible={clientiCategorieUseTreelist}
                dataField="padre"
                editorType="dxSelectBox"
                editorOptions={clientiCategorieSelectBox}>
                  <Label text="Padre" />
              </SimpleItem>
              */}
            </React.Fragment>
          );
        }}
      </EntityEditPage.EditForm>
      {displayScadenzeBox && 
        <div className='dx-card form-card'>
          <CorsiScadenzePage
            filtri={{
              clientiRecord: currentDatarow
            }}
          />
        </div>
      }
  </EntityEditPage>
}