import React from 'react';

import { SvcTagBox } from '@/svc/components';

export default function ContattiFlagsList(props) {
  const { value, setValue } = props.data.editorOptions;

  const onValueChanged = ({value}) => {
    if (value) {
      for (let v of value) {
        v.selezionato = true;
      }
    }
    
    setValue(value);
  }

  return (
    <SvcTagBox
      domain='Core'
      entity='contatti'
      listOpts={{
        command: 'flagsList',
        responseField: 'flagsList'
      }}
      lookupKey='codice'
      value={value}
      setValue={onValueChanged}
    />
  );
}

ContattiFlagsList.key = 'contattiFlagsList';
ContattiFlagsList.label = 'Tipologia Contatto';
