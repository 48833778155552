import React, { useMemo } from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage } from '@/svc';

export default function ClientiPage() {
  // Attenzione: è intenzionale esternalizzare i filtri e non lasciarli inline
  // in quanto se si facesse diversamente, ogni cambiamento allo state
  // implicherebbe un rerender di tutto l'albero, incluso il box dei filtri
  // che tornerebbe al suo state iniziale.
  const filtersBox = useMemo(() => <EntityListPage.Filters
    maxEntries={{
      initialValue: 100
    }}
    genericText
    piva
    codice_fiscale
  />, []);

  return (
    <EntityListPage
      domain='Core'
      entity='clienti'
      editRoute='/core/Clienti/edit'
      filtersResponseKey='anagraficaFiltriNew'
    >
      {filtersBox}
      <EntityListPage.Columns>
        <Column
          caption='Ragione Sociale'
          dataField='nome'
          dataType='string'
        >
          <RequiredRule />
        </Column>
        {/* <Column
          caption='Codice Fiscale'
          dataField='cfisc'
          dataType='string' /> */}
        <Column
          caption='P. Iva'
          dataField='piva'
          dataType='string' />
        <Column
          caption='Indirizzo'
          dataField='indirizzoCompleto'
          dataType='string' />
        <Column
          caption='Telefono'
          dataField='telefono'
          dataType='string' />
        <Column
          caption='Email'
          dataField='email'
          dataType='string' />
      </EntityListPage.Columns>
    </EntityListPage>
  );
}