import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Column, RequiredRule } from 'devextreme-react/data-grid';

import { EntityListPage, EntitySendSmsDialog } from '@/svc';
import { Fragment } from 'react';
import { Toast } from '@/ui/utils';
import { Item } from 'devextreme-react/toolbar';

export default function ContattiPage() {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [displaySmsDialog, setDisplaySmsDialog] = useState(false);
  const listPageRef = useRef();

  const selectedSmsNumbers = useMemo(() => {
    return selectedContacts?.map(({sms}) => sms).filter(n => n !== null && n !== void 0);
  }, [selectedContacts])

  const onSelectionChanged = useCallback(({ selectedRowsData }) => {
    setSelectedContacts(selectedRowsData);
  }, []);

  const onSmsDialogClosed = () => {
    setSelectedContacts([]);
    setDisplaySmsDialog(false);
    listPageRef.current.clearSelection();
  }

  const sendMultipleSmsButtonOptions = useMemo(() => ({
    text: 'Invia SMS ai contatti selezionati',
    type: 'success',
    stylingMode: 'outlined',
    onClick: async ({ component }) => {
      if (selectedSmsNumbers.length > 0) {
        setDisplaySmsDialog(true);
      }
      else {
        Toast('info', 'Nessun contatto selezionato ha un numero collegato.', 5000);
      }
    }
  }), [selectedSmsNumbers]);


  // Attenzione: è intenzionale esternalizzare i filtri e non lasciarli inline
  // in quanto se si facesse diversamente, ogni cambiamento allo state
  // implicherebbe un rerender di tutto l'albero, incluso il box dei filtri
  // che tornerebbe al suo state iniziale.
  const filtersBox = useMemo(() => <EntityListPage.Filters
    maxEntries={{
      initialValue: 100
    }}
    clientiRecord={{
      initialValue: null
    }}
    genericText
    contattiFlagsList
  />, []);

  return (
    <Fragment>
      <EntityListPage
        ref={listPageRef}
        key='contattiList'
        domain='Core'
        entity='contatti'
        editRoute='/core/Contatto/edit'
        selectionMode='multiple'
        onSelectionChanged={onSelectionChanged}
      >
        <EntityListPage.ToolbarItems>
          <Item 
            location="after"
            locateInMenu="never"
            widget="dxButton"
            visible={selectedContacts.length > 0}
            options={sendMultipleSmsButtonOptions} />
        </EntityListPage.ToolbarItems>
        {filtersBox}
        <EntityListPage.Columns>
          <Column
            caption='Nome'
            dataField='nome'
            dataType='string'
            >
              <RequiredRule />
          </Column>
          <Column
            caption='Cognome'
            dataField='cognome'
            dataType='string'>
              <RequiredRule />
          </Column>
          <Column
            caption='Telefono'
            dataField='telefono'
            dataType='string'>
              <RequiredRule />
          </Column>
          <Column
            caption='SMS'
            dataField='sms'
            dataType='string'>
              <RequiredRule />
          </Column>
          <Column
            caption='Email'
            dataField='email'
            dataType='string'>
              <RequiredRule />
          </Column>
          <Column
            caption='Azienda'
            dataField='clientiDatiList[0].clientiRecord.displayText'
            dataType='string'>
          </Column>
          <Column
            caption='Inizio rapporto'
            dataField='clientiDatiList[0].data_assunzione'
            dataType='date'>
          </Column>
          <Column
            caption='Fine rapporto'
            dataField='clientiDatiList[0].data_licenziamento'
            dataType='date'>
          </Column>
        </EntityListPage.Columns>
      </EntityListPage>
      <EntitySendSmsDialog
        domain='Core'
        entity='contatti'
        sendNumbers={selectedSmsNumbers}
        sendMode='multiple-server'
        modalTitle='Invia SMS'
        modalVisible={displaySmsDialog}
        onModalClosed={onSmsDialogClosed}
      />
    </Fragment>
  );
}